import React, { useEffect, useState } from 'react'

// Import assets
import referencesIcon from '../../assets/icons/references.svg'
import referencesIconHover from '../../assets/icons/references_hover.svg'
import learningIcon from '../../assets/icons/feedback-icon.png'
import learningIconHover from '../../assets/icons/feedback-icon_hover.png'
import threeSixtyIcon from '../../assets/icons/360.svg'
import feedbackIcon from '../../assets/icons/flame.svg'
import feedbackIconHover from '../../assets/icons/flame_hover.svg'

// Redux
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

const Footer = () => {
  // Redux
  const common = useSelector(state => state)
  const dispatch = useDispatch()

  const [referencesIconImage, setReferencesIconImage] = useState(referencesIcon)
  const [feedbackIconImage, setFeedbackIconImage] = useState(feedbackIcon)
  const [learningIconImage, setLearningIconImage] = useState(learningIcon)

  useEffect(() => {
    if (
      common.highlightedElements.header === true &&
      common.highlightedElements.footer === false
    ) {
      let el, nextEl, prevId
      const timer = ms => new Promise(res => setTimeout(res, ms))
      const highlightItem = async () => {
        for (var id = 1; id < 4; id++) {
          prevId = id - 1
          el = document.getElementById('footer-text-' + prevId)
          nextEl = document.getElementById('footer-text-' + id)
          if (el) {
            el.classList.remove('active-green')
          }
          nextEl && nextEl.classList.add('active-green')
          await timer(1000)
        }
        document.getElementById('footer-text-3') &&
          document
            .getElementById('footer-text-3')
            .classList.remove('active-green')
        dispatch({
          type: 'ITEMS_HIGHLIGHTED',
          legend: true,
          header: true,
          footer: true
        })
      }
      highlightItem()
    }
  }, [
    common.highlightedElements.header,
    common.highlightedElements.footer,
    dispatch
  ])

  const handleReferencesClick = () => {
    dispatch({
      type: 'OPEN_WIDGET',
      payload: 'testimonials'
    })
  }

  const handleReferencesHover = status => {
    if (status) {
      setReferencesIconImage(referencesIconHover)
    } else {
      setReferencesIconImage(referencesIcon)
    }
  }

  const handleLearningHover = status => {
    if (status) {
      setLearningIconImage(learningIconHover)
    } else {
      setLearningIconImage(learningIcon)
    }
  }

  const handle360Click = () => {
    dispatch({
      type: 'ROTATE_MODEL'
    })
  }

  const handleFeedbackClick = () => {
    dispatch({
      type: 'OPEN_WIDGET',
      payload: 'problems'
    })
  }

  const handleLearningClick = () => {
    dispatch({
      type: 'OPEN_WIDGET',
      payload: 'learnings'
    })
  }

  const handleFeedbackHover = status => {
    if (status) {
      setFeedbackIconImage(feedbackIconHover)
    } else {
      setFeedbackIconImage(feedbackIcon)
    }
  }

  return (
    <div className='gc-footer'>
      <div
        className='left'
        onClick={() => handleReferencesClick()}
        onMouseOver={() => handleReferencesHover(true)}
        onMouseLeave={() => handleReferencesHover(false)}
      >
        <img
          className='gc-header-icon-references link'
          src={referencesIconImage}
          alt='Reference icon'
        />
        <span id='footer-text-1'>
          {common?.data?.footer?.referenceMessage?.label}
        </span>
      </div>
      <div className='left' style={{ width: '50px', marginRight: '50px' }}>
        <img
          className='gc-header-icon-360 link'
          src={threeSixtyIcon}
          alt='360 icon'
          onClick={() => handle360Click()}
        />
      </div>
      <div
        className='left'
        onClick={() => handleLearningClick()}
        onMouseOver={() => handleLearningHover(true)}
        onMouseLeave={() => handleLearningHover(false)}
      >
        <img
          className='gc-header-icon-references link'
          src={learningIconImage}
          alt='Learnings icon'
        />
        <span id='footer-text-2'>{common?.data?.footer?.learnings?.label}</span>
      </div>
      <div
        className='right'
        onClick={() => handleFeedbackClick()}
        onMouseOver={() => handleFeedbackHover(true)}
        onMouseLeave={() => handleFeedbackHover(false)}
      >
        <span id='footer-text-3'>{common?.data?.footer?.problems?.label}</span>
        <img
          className='gc-header-icon-feedback link'
          src={feedbackIconImage}
          alt='Feedback icon'
        />
      </div>
    </div>
  )
}

export default Footer
