import React, { useState } from 'react'
import Modal from 'react-modal'
import {
  modalContentImageGallery,
  modalContentDownload
} from '../modal/helpers'

// Redux
import { useSelector, useDispatch } from 'react-redux'

Modal.setAppElement('#root')

const Content = props => {
  // Redux
  const common = useSelector(state => state)
  const dispatch = useDispatch()
  const { selectedPoint, roomId, subRoomId } = props
  const initialValues = {
    modalOpen: true,
    infoModalOpen: false,
    point: 1,
    currentSlide: 0,
    modalStyle: {
      content: {
        width: '750px',
        height: '375px'
      }
    },
    infoModalStyle: {
      content: {
        width: '750px',
        height: '444px'
      }
    }
  }

  const modalStyle = initialValues.modalStyle
  const [infoModalStyle, setInfoModalStyle] = useState(
    initialValues.infoModalStyle
  )
  const [modalOpen, setModalOpen] = useState(initialValues.modalOpen)
  const [infoModalOpen, setInfoModalOpen] = useState(
    initialValues.infoModalOpen
  )
  const [downloadForm, setDownloadForm] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(initialValues.currentSlide)
  // const data = common?.data?.content[0].points[0][initialValues.point].images
  let data = []
  const content = common?.data?.content
  let points = []
  content.map(room => {
    if (room.meta.id === roomId) {
      points = room.points
    }
  })
  if (points) {
    Object.keys(points).forEach((index, item) => {
      if (subRoomId) {
        if (points[index].point === subRoomId) {
          data = points[index].points[selectedPoint].images
        }
      } else {
        if (points[index].point === selectedPoint) {
          data = points[index].images
        }
      }
    })
  }
  const dataDownload = common?.data?.download
  const toggleModal = () => {
    setModalOpen(!modalOpen)
    dispatch({ type: 'CHANGE_POINT', payload: false })
  }

  const toggleInfoModal = () => {
    setInfoModalOpen(!infoModalOpen)
  }

  const toggleDownloadForm = () => {
    setDownloadForm(!downloadForm)
    setInfoModalStyle({
      content: {
        width: '400px',
        height: '444px'
      }
    })
  }

  const sliderSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: index => {
      setCurrentSlide(index)
    }
  }

  return (
    <>
      <Modal
        isOpen={modalOpen}
        onRequestClose={toggleModal}
        className='gc-modal'
        overlayClassName='gc-modal-overlay'
        style={modalStyle}
      >
        {modalContentImageGallery(
          toggleModal,
          sliderSettings,
          currentSlide,
          data
        )}
      </Modal>
      <Modal
        isOpen={infoModalOpen}
        onRequestClose={toggleInfoModal}
        className='gc-modal'
        overlayClassName='gc-modal-overlay'
        style={infoModalStyle}
      >
        {modalContentDownload(
          toggleInfoModal,
          dataDownload,
          toggleDownloadForm,
          downloadForm
        )}
      </Modal>
    </>
  )
}

export default Content
