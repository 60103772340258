import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import Header from '../components/common/Header'
import Footer from '../components/common/Footer'

import { modalRoomYoutube } from '../components/modal/helpers'

// Redux
import { useSelector, useDispatch } from 'react-redux'

// Components
import { SketchFabView } from 'components/SketchFab.js'
import { IsoCertificates } from 'components/IsoCertificates.js'
import Testimonials from 'components/widgets/TestimonialsLogos/Testimonials.js'
import Learnings from 'components/widgets/Learnings/Learnings.js'
import Problems from 'components/widgets/Problems/Problems.js'
import QA from 'components/widgets/QA/QA.js'
import Contact from 'components/widgets/Contact/Contact.js'
import Exit from 'components/exit/exit'
import Overview from 'components/overview/overview'
import Content from 'components/content/content'
import FAQ from 'components/faq/faq'
import { Pannellum } from 'pannellum-react'

// assets
import threeSixtyIcon from '../assets/icons/360.svg'
import leftArrow from '../assets/icons/left-arrow.png'
import rightArrow from '../assets/icons/right-arrow.png'
import custom360Icon from '../assets/icons/info-white-bg.png'

Modal.setAppElement('#root')

const Desktop = () => {
  // Redux
  const common = useSelector(state => state)

  let roomImage
  let roomPitch = -30
  let roomYaw = -20
  let roomHfov = 400
  const dispatch = useDispatch()
  const content = common.data.content
  let roomDescription = ''
  const [subRoomId, setSubroomId] = useState(null)
  const [selectedPoint, setSelectedPoint] = useState(null)
  const [panoramaLoaded, setPanoramaLoaded] = useState(false)
  const [initModal, setInitModal] = useState(true)
  let navigateLeft = false
  let navigateRight = false
  let navigatedPoint = null
  const initialValues = {
    modalOpen: true
  }
  const [modalOpen, setModalOpen] = useState(initialValues.modalOpen)
  useEffect(() => {
    if (common.modalOpen && common.modalOpen === true) {
      if (common.roomId === null) {
        setInitModal(true)
      } else {
        setModalOpen(true)
      }
    }
    if (common.modalDisplayed === false && common.roomId !== null) {
      setModalOpen(true)
    }

    // HOT FIX, when subroomid is definied first then rooms without subroom doesn't display modal content
    if (common.roomId === 2) {
      setSubroomId(null)
    }
  }, [common])
  const toggleModal = () => {
    setModalOpen(!modalOpen)
    dispatch({ type: 'MODAL_ROOM_DESCRIPTION', modalDisplayed: true })
    dispatch({ type: 'ROOM_ID', payload: roomId })
  }

  const toggleVideoModal = () => {
    setInitModal(!initModal)
    dispatch({ type: 'MODAL_HOME_VIDEO', modalHomeVideoDisplayed: true })
  }
  const roomId = common.roomId

  let imagePoints = []
  let roomPoints = []
  let points = {}

  const hotspotTooltip = (hotSpotDiv, args) => {
    if (args.text) {
      const helpText = document.createElement('span')
      helpText.setAttribute('class', 'gc-help-text')
      helpText.innerHTML = args.text
      hotSpotDiv.appendChild(helpText)
    }

    const imageDiv = document.createElement('img')
    imageDiv.setAttribute('width', '45')
    imageDiv.setAttribute('height', '45')
    imageDiv.setAttribute('src', custom360Icon)
    hotSpotDiv.appendChild(imageDiv)
  }

  content.forEach(item => {
    if (item.meta.id === roomId) {
      if (subRoomId !== null && item.points[subRoomId]) {
        points = item.points[subRoomId].points
        roomDescription = ''
        roomImage = item.points[subRoomId].source.url
        roomPitch = item.points[subRoomId].source.pitch
        roomYaw = item.points[subRoomId].source.yaw
        roomHfov = item.points[subRoomId].source.hfov
      } else {
        points = item.points
        roomDescription = item.meta.description
        roomImage = item.meta.source.url
        roomPitch = item.meta.source.pitch
        roomYaw = item.meta.source.yaw
        roomHfov = item.meta.source.hfov
      }
      Object.keys(points).forEach(index => {
        const point = points[index]
        switch (point.type) {
          case 'image':
            const image = point.images[0]
            const clickPoint = point.point
            imagePoints.push(
              <Pannellum.Hotspot
                key={index}
                type='custom'
                pitch={image.pitch}
                yaw={image.yaw}
                text={image.title}
                tooltip={div => hotspotTooltip(div, { text: image.title })}
                handleClick={(evt, name) => {
                  handleClickPoint(clickPoint)
                }}
                name='hs01'
              />
            )
            break
          case 'room':
            roomPoints.push(point)
            navigatedPoint = point
            if (point.direction === 'left') {
              navigateLeft = point.point
            }
            if (point.direction === 'right') {
              navigateRight = point.point
            }
            break
          default:
        }
      })
    }
  })

  const handleClickPoint = point => {
    setSelectedPoint(point)
    dispatch({ type: 'CHANGE_POINT', payload: true })
  }

  const handleArrowClick = (pointId, navigatedPoint) => {
    setPanoramaLoaded(false)
    setSubroomId(pointId)
    if (subRoomId != null) {
      if (navigatedPoint.base === true) {
        setSubroomId(null)
      }
    }
  }
  return (
    <div className='gc-layout-desktop'>
      <Header />
      {common.pageType && common.pageType === 'room' && (
        <>
          {roomDescription && modalOpen && (
            <div className='room-description-wrapper-v1'>
              <div
                className='room-description-close-v1'
                onClick={() => toggleModal()}
              >
                close X
              </div>
              <div
                className='room-description-v1'
                dangerouslySetInnerHTML={{ __html: roomDescription }}
              />
            </div>
          )}
          {panoramaLoaded && (
            <div className='navigation-arrows'>
              {navigateLeft && (
                <div className='left-arrow'>
                  <a
                    onClick={evt => {
                      handleArrowClick(navigateLeft, navigatedPoint)
                    }}
                  >
                    <div className='gc-help-text'>Change view</div>
                    <img src={leftArrow} alt='greencooling' />
                  </a>
                </div>
              )}
              {navigateRight && (
                <div className='right-arrow'>
                  <a
                    onClick={(evt, name) => {
                      handleArrowClick(navigateRight, navigatedPoint)
                    }}
                  >
                    <div className='gc-help-text'>Change view</div>
                    <img src={rightArrow} alt='greencooling' />
                  </a>
                </div>
              )}
            </div>
          )}
          <Overview />
          <Pannellum
            width='100%'
            height='500px'
            image={roomImage}
            pitch={roomPitch}
            yaw={roomYaw}
            autospin={1}
            hfov={roomHfov}
            autoLoad
            showZoomCtrl={false}
            showFullscreenCtrl={false}
            onLoad={evt => {
              setPanoramaLoaded(true)
            }}
          >
            {imagePoints.map(point => {
              return point
            })}
          </Pannellum>
          {selectedPoint && common.changePoint === true && roomId != null && (
            <Content
              selectedPoint={selectedPoint}
              roomId={roomId}
              subRoomId={subRoomId}
            />
          )}
          {panoramaLoaded && (
            <div className='icon-360-cower'>
              <div className='icon-360'>
                <img src={threeSixtyIcon} alt='greencooling' />
              </div>
            </div>
          )}

          <Contact />
          <Testimonials />
          <QA />
          <Problems />
          <Learnings />
        </>
      )}

      <div
        className='gc-stage'
        style={{
          display:
            common.pageType && common.pageType === 'index' ? 'block' : 'none'
        }}
      >
        <SketchFabView content={content} />
        {/* youtube */}
        {common.data.home?.initVideo?.display &&
          common.data.home?.initVideo?.youtubeLink && (
            <Modal
              isOpen={initModal}
              onRequestClose={() => toggleVideoModal}
              className='gc-modal gc-modal-auto-with'
              overlayClassName='gc-modal-overlay'
            >
              {modalRoomYoutube(
                toggleVideoModal,
                common.data.home?.initVideo?.youtubeLink,
                common.data.home?.initVideo?.width,
                common.data.home?.initVideo?.height,
                common.data.home?.initVideo?.text
              )}
              <div className='gc-modal-nopadding gc-modal-iframe'>
                <div
                  className='gc-modal-text'
                  onClick={() => toggleVideoModal()}
                >
                  <a className='gc-btn'>Skip video</a>
                </div>
              </div>
            </Modal>
          )}

        {/* end youtube */}
        {common.displayedElements.certificates && <IsoCertificates />}
      </div>
      {common.pageType && common.pageType === 'index' && (
        <>
          <Contact />
          <Testimonials />
          <QA />
          <Problems />
          <Learnings />
          <Footer />
          {common.displayedElements.exit && <Exit />}
        </>
      )}
      {common.pageType && common.pageType === 'faq' && (
        <>
          <Contact />
          <Testimonials />
          <QA />
          <Problems />
          <Learnings />
          <Footer />
          <FAQ />
          <Overview />
        </>
      )}
    </div>
  )
}

export default Desktop
