import React from 'react'
import Slider from 'react-slick'

// Components
import Testimonial from './Testimonial'

// Assets
import ArrowDown from '../../../assets/icons/arrow_down.png'

// Redux
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

// Import css files
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Testimonials = () => {
  // Redux
  const opened = useSelector(state => state.openedWidgets.testimonials)
  const footer = useSelector(state => state.data.footer)
  const testimonialsLogos = useSelector(state => state.data.testimonialsLogos)

  const dispatch = useDispatch()

  const closeWidget = () => {
    dispatch({ type: 'CLOSE_WIDGET', payload: 'testimonials' })
  }

  const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 3
  }

  if (!opened) {
    return true
  }

  return (
    <div className='gc-testimonials-logos gc-widget animation'>
      <div className='head'>
        <div className='title'>
          {footer?.referenceMessage.title}
          <hr
            style={{
              maxWidth: '25px',
              margin: '5px 0px 0px 0px'
            }}
          />
        </div>
        <div className='arrow' onClick={closeWidget}>
          <img src={ArrowDown} alt='Arrow down' />
        </div>
      </div>
      <div className='body slick-wrapper'>
        <Slider {...slickSettings}>
          {testimonialsLogos.map((testimonial, i) => {
            return (
              <Testimonial
                key={i}
                image={testimonial.image}
                link={testimonial.link}
              />
            )
          })}
        </Slider>
      </div>
    </div>
  )
}

export default Testimonials
