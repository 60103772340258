import React, { useEffect } from 'react'

// Import custom Components
import Mobile from 'layouts/Mobile'
import Desktop from 'layouts/Desktop'
import CookieConsent from './components/CookieConsent'

// Redux
import { useSelector, useDispatch } from 'react-redux'

const App = () => {
  const width = useSelector(state => state.dimensions.width)
  const dispatch = useDispatch()

  useEffect(() => {
    // First set dimensions
    updateDimensions()
    // Setup listener for resize on mount
    window && window.addEventListener('resize', updateDimensions)
    // Disconnect listener on unmount
    return () => {
      window && window.removeEventListener('resize', updateDimensions)
    }
  })

  const updateDimensions = () => {
    if (window) {
      // Get width and change state
      const width = window.innerWidth
      const height = window.innerHeight
      dispatch({
        type: 'RESIZE_WINDOW',
        payload: { width: width, height: height }
      })
    }
  }

  // Detect viewport
  const isMobile = width > 860 ? false : true

  return (
    <div className='App'>
      <CookieConsent />
      {isMobile && <Mobile />}
      {!isMobile && <Desktop />}
    </div>
  )
}

export default App
