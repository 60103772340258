import React from 'react'
import { createStore } from 'redux'
import { Provider } from 'react-redux'

import reducer from './reducer'

const store = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

const ReduxProvider = props => {
  return <Provider store={store}>{props.children}</Provider>
}

export default ReduxProvider
