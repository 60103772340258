import React from 'react'

// Assets
import IconQA from '../../../assets/icons/q_a.svg'

const Problem = props => {
  const { text } = props

  return (
    <div className='question'>
      <div className='icon'>
        <img src={IconQA} width='29' alt='QA' />
      </div>
      <div className='text' dangerouslySetInnerHTML={{ __html: text }}></div>
    </div>
  )
}

export default Problem
