import React from 'react'

// Assets
import IconDesktop from '../../assets/icons/icon_desktop.png'

// Redux
import { useSelector } from 'react-redux'

const Mobile = () => {
  const translations = useSelector(state => state.data.translations.mobile)

  return (
    <div className='gc-modal-window'>
      <div className='gc-modal-mobile'>
        <div className='subtitle'>{translations.subtitleTop}</div>
        <div className='title'>{translations.title}</div>
        <div>
          <img
            className='gc-header-icon-exit modal-exit-icon'
            src={IconDesktop}
            alt='Exit icon'
          />
        </div>
        <div className='subtitle'>{translations.subtitleBottom}</div>
      </div>
    </div>
  )
}

export default Mobile
