import React from 'react'

// Assets
import IconFire from '../../../assets/icons/icon_fire.png'

const Problem = props => {
  const { text } = props

  return (
    <div className='problem'>
      <div className='icon'>
        <img src={IconFire} alt='Fire' />
      </div>
      <div className='text' dangerouslySetInnerHTML={{ __html: text }}></div>
    </div>
  )
}

export default Problem
