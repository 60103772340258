// Import data for project
import data from 'data.json'
import { map } from 'lodash'

const INITIAL_STATE = {
  data: data,
  pageType: 'index',
  roomId: null,
  rotate3DModel: false,
  changePoint: false,
  modalDisplayed: false,
  modalHomeVideoDisplayed: false,
  modalOpen: true,
  dimensions: {
    width: null,
    height: null
  },
  openedWidgets: {
    testimonials: false,
    qa: false,
    problems: false,
    learnings: false,
    contactPhone: false,
    contactEmail: false
  },
  displayedElements: {
    legend: false,
    certificates: false,
    exit: false
  },
  highlightedElements: {
    legend: false,
    header: false,
    footer: false
  }
}

export default (state = INITIAL_STATE, action) => {
  let newState

  switch (action.type) {
    case 'FETCH_DATA':
      return {
        ...state,
        data: action.payload
      }

    case 'MODEL_LOADED':
      return {
        ...state,
        displayedElements: {
          legend: true,
          certificates: true,
          exit: true
        }
      }

    case 'ROTATE_MODEL':
      return {
        ...state,
        rotate3DModel: !state.rotate3DModel
      }
    case 'ITEMS_HIGHLIGHTED':
      return {
        ...state,
        highlightedElements: {
          legend: action.legend,
          header: action.header,
          footer: action.footer
        }
      }

    case 'RESIZE_WINDOW':
      return {
        ...state,
        dimensions: action.payload
      }

    case 'OPEN_WIDGET':
      newState = { ...state }
      const newValue = state.openedWidgets[action.payload] ? false : true
      newState.openedWidgets[action.payload] = newValue

      // Close other widgets
      map(state.openedWidgets, (widget, index) => {
        if (index !== action.payload) {
          newState.openedWidgets[index] = false
        }
      })
      return newState

    case 'CLOSE_WIDGET':
      newState = { ...state }

      // Close all widgets
      map(state.openedWidgets, (widget, index) => {
        newState.openedWidgets[index] = false
      })

      return newState

    case 'PAGE_TYPE':
      newState = { ...state }

      // Close all widgets
      map(state.openedWidgets, (widget, index) => {
        newState.openedWidgets[index] = false
      })
      return {
        ...state,
        pageType: action.payload
      }
    case 'ROOM_ID':
      newState = { ...state }

      // Close all widgets
      map(state.openedWidgets, (widget, index) => {
        newState.openedWidgets[index] = false
      })
      return {
        ...state,
        roomId: action.payload
      }
    case 'CHANGE_POINT':
      newState = { ...state }
      return {
        ...state,
        changePoint: action.payload
      }
    case 'MODAL_ROOM_DESCRIPTION':
      newState = { ...state }
      return {
        ...state,
        modalDisplayed: action.modalDisplayed,
        modalOpen: action.modalOpen
      }
    case 'MODAL_HOME_VIDEO':
      newState = { ...state }
      return {
        ...state,
        modalHomeVideoDisplayed: action.modalHomeVideoDisplayed,
        modalOpen: action.modalOpen
      }

    case 'GENERAL_ROOM_CHANGE':
      newState = { ...state }

      // Close all widgets
      map(state.openedWidgets, (widget, index) => {
        newState.openedWidgets[index] = false
      })

      return {
        ...state,
        pageType: 'index',
        roomId: null,
        modalDisplayed: false,
        modalHomeVideoDisplayed: true,
        modalOpen: false
      }

    default:
      return state
  }
}
