import React from 'react'
import certificate1 from '../assets/images/dekra-logo1.png'
import certificate2 from '../assets/images/dekra-logo2.png'
import criticalCoolingLogo from '../assets/images/mission-critical-cooling-logo.png'

export const IsoCertificates = () => {
  return (
    <>
      <div className='iso-certificates'>
        <img
          className='certificate1'
          src={certificate1}
          alt='Dekra zertifiziert'
        />
        <img
          className='certificate2'
          src={certificate2}
          alt='Dekra zertifiziert'
        />
        <div className="critical-cooling">
          <img
            className='logo'
            src={criticalCoolingLogo}
            alt='Mission Critical Cooling'
          />
        </div>
      </div>
    </>
  )
}
