import React, { useEffect, useState } from 'react'

// Import assets
// import flagIcon from '../../assets/icons/en_flag.svg'
import titleIcon from '../../assets/icons/title-icon.png'
import titleIconHover from '../../assets/icons/title-icon_hover.png'
import logo from '../../assets/images/logo_green_cooling.png'
import qaIcon from '../../assets/icons/q_a.svg'
import qaIconHover from '../../assets/icons/q_a_hover.svg'
import meetingIcon from '../../assets/icons/meeting.svg'
import meetingIconHover from '../../assets/icons/meeting_hover.svg'
import phoneIcon from '../../assets/icons/phone.svg'
import phoneIconHover from '../../assets/icons/phone_hover.svg'
import emailIcon from '../../assets/icons/envelope.svg'
import emailIconHover from '../../assets/icons/envelope_hover.svg'

// Redux
import { useSelector, useDispatch } from 'react-redux'

const Header = () => {
  // Redux
  const common = useSelector(state => state)
  const dispatch = useDispatch()
  const [qaIconImage, setQaIconImage] = useState(qaIcon)
  const [meetingIconImage, setMeetingIconImage] = useState(meetingIcon)
  const [emailIconImage, setEmailIconImage] = useState(emailIcon)
  const [phoneIconImage, setPhoneIconImage] = useState(phoneIcon)
  const [titleIconImage, setTitleIconImage] = useState(titleIcon)
  const [titleMessage, setTitleMessage] = useState('title-message')
  const displayFlag = true
  let welcomeMessage = common?.data?.header?.welcomeMessage
  const readMoreMessage = common?.data?.header?.readMoreMessage
  const calendlyUrl = common?.data?.header?.calendlyUrl
  let titleAnchor = false

  useEffect(() => {
    if (common.highlightedElements.legend === true) {
      setTitleMessage('title-message active')
      setTimeout(() => {
        setTitleMessage('title-message')
        dispatch({
          type: 'ITEMS_HIGHLIGHTED',
          legend: true,
          header: true,
          footer: false
        })
      }, 1000)
    }
  }, [common.highlightedElements.legend, dispatch])

  if (common.modalDisplayed && common.modalDisplayed === true) {
    welcomeMessage =
      readMoreMessage + common?.data?.content[common.roomId - 1].meta.title
    titleAnchor = true
  }

  if (
    common.modalHomeVideoDisplayed &&
    common.modalHomeVideoDisplayed === true &&
    common.roomId === null
  ) {
    welcomeMessage = common?.data?.header?.headline
    titleAnchor = true
  }

  const handleQandAClick = () => {
    // dispatch({ type: 'PAGE_TYPE', payload: 'faq' })
    dispatch({ type: 'OPEN_WIDGET', payload: 'qa' })
  }

  const handleQandAHover = status => {
    if (status) {
      setQaIconImage(qaIconHover)
    } else {
      setQaIconImage(qaIcon)
    }
  }

  const handleMeetingClick = () => {
    window.open(calendlyUrl, '_blank')
  }

  const handleMeetingHover = status => {
    if (status) {
      setMeetingIconImage(meetingIconHover)
    } else {
      setMeetingIconImage(meetingIcon)
    }
  }

  const handleContactClick = contactType => {
    dispatch({
      type: 'OPEN_WIDGET',
      payload: contactType
    })
  }

  const handleContactHover = status => {
    if (status) {
      setEmailIconImage(emailIconHover)
    } else {
      setEmailIconImage(emailIcon)
    }
  }

  const handlePhoneHover = status => {
    if (status) {
      setPhoneIconImage(phoneIconHover)
    } else {
      setPhoneIconImage(phoneIcon)
    }
  }

  const handleTitleHover = status => {
    if (status) {
      setTitleIconImage(titleIconHover)
    } else {
      setTitleIconImage(titleIcon)
    }
  }

  const handleModalOpen = () => {
    if (common.roomId === null) {
      dispatch({
        type: 'MODAL_HOME_VIDEO',
        modalHomeVideoDisplayed: false,
        modalOpen: true
      })
    } else {
      dispatch({
        type: 'MODAL_ROOM_DESCRIPTION',
        modalDisplayed: false,
        modalOpen: true
      })
    }
  }
  const refreshPage = () => {
    window.location.reload(false)
  }
  return (
    <div className='gc-header'>
      <div className='left'>
        <span
          className='welcome-message'
          onClick={handleModalOpen}
          onMouseOver={() => handleTitleHover(true)}
          onMouseLeave={() => handleTitleHover(false)}
        >
          {displayFlag && (
            <img
              className='gc-header-icon-flag'
              src={titleIconImage}
              alt='English flag'
            />
          )}
        </span>
        {titleAnchor && titleAnchor === true && (
          <span>
            <a
              href={'#'}
              className={titleMessage}
              onClick={handleModalOpen}
              onMouseOver={() => handleTitleHover(true)}
              onMouseLeave={() => handleTitleHover(false)}
            >
              {welcomeMessage}
            </a>
          </span>
        )}
        {!titleAnchor && <span>{welcomeMessage}</span>}
      </div>
      <div className='center'>
        <a href={'#'} onClick={refreshPage}>
          <img
            className='gc-header-logo link'
            src={logo}
            alt='Logo green cooling'
          />
        </a>
      </div>
      <div className='right'>
        <img
          className='gc-header-icon-qa link'
          src={qaIconImage}
          alt='Questions and Answers'
          onClick={() => handleQandAClick()}
          onMouseOver={() => handleQandAHover(true)}
          onMouseLeave={() => handleQandAHover(false)}
        />
        <img
          className='gc-header-icon-meeting link'
          src={meetingIconImage}
          alt='Meeting icon'
          onClick={() => handleMeetingClick()}
          onMouseOver={() => handleMeetingHover(true)}
          onMouseLeave={() => handleMeetingHover(false)}
        />
        <img
          className='gc-header-icon-phone link'
          src={phoneIconImage}
          alt='Email icon'
          onClick={() => handleContactClick('contactPhone')}
          onMouseOver={() => handlePhoneHover(true)}
          onMouseLeave={() => handlePhoneHover(false)}
        />
        <img
          className='gc-header-icon-email link'
          src={emailIconImage}
          alt='Email icon'
          onClick={() => handleContactClick('contactEmail')}
          onMouseOver={() => handleContactHover(true)}
          onMouseLeave={() => handleContactHover(false)}
        />
      </div>
    </div>
  )
}

export default Header
