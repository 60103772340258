import React, { useEffect, useRef, useState } from 'react'
import Modal from 'react-modal'
import { modalContentRoomDetail } from './modal/helpers'

// Redux
import { useSelector, useDispatch } from 'react-redux'

// Id of model from sketchfab cloud
const MODEL_UID = 'f33e75dbfb8f4df09b7a187944ecae08'

export const SketchFabView = props => {
  const content = props.content
  const common = useSelector(state => state)
  const height = useSelector(state => state.dimensions.height)
  const legendItems = useSelector(state => state?.data?.indexTooltip?.rooms)
  const legendTitle = useSelector(state => state?.data?.indexTooltip?.title)
  const rotate3DModel = useSelector(state => state?.rotate3DModel)
  const displayedElements = useSelector(state => state?.displayedElements)
  const dispatch = useDispatch()
  const initialValues = {
    modalOpen: false,
    currentSlide: 0,
    modalStyle: {
      content: {
        width: '750px',
        height: '375px'
      }
    },
    sliderSettings: {
      dots: false,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: index => {
        setCurrentSlide(index)
      }
    }
  }

  const [modalOpen, setModalOpen] = useState(initialValues.modalOpen)
  const [roomData, setRoomData] = useState()
  const [sketchfabClient, setSketchfabClient] = useState()
  const [currentSlide, setCurrentSlide] = useState(initialValues.currentSlide)

  const [activeItem] = useState()
  const [legendItemClass, setLegendItemClass] = useState('legend-item')
  const [numberClass, setNumberClass] = useState('number')
  let legendItemId = 0

  useEffect(() => {
    if (common.displayedElements.legend === true) {
      let el, nextEl, prevId
      const timer = ms => new Promise(res => setTimeout(res, ms))
      const highlightItem = async () => {
        for (var id = 0; id < 6; id++) {
          prevId = id - 1
          el = document.getElementById('legendItem_' + prevId)
          nextEl = document.getElementById('legendItem_' + id)
          if (el) {
            el.classList.remove('active-green')
          }
          nextEl && nextEl.classList.add('active-green')
          await timer(1000)
        }
        document.getElementById('legendItem_5') &&
          document
            .getElementById('legendItem_5')
            .classList.remove('active-green')
        dispatch({
          type: 'ITEMS_HIGHLIGHTED',
          legend: true,
          header: false,
          footer: false
        })
      }
      highlightItem()
    }
  }, [common.displayedElements.legend, dispatch])

  const handleClick = i => {
    const position = i + 1
    if (position !== 0) {
      // modal
      if (position === 5 || position === 6) {
        setRoomData(content[position - 1])
        setModalOpen(true)
      } else {
        // open room
        dispatch({ type: 'PAGE_TYPE', payload: 'room' })
        dispatch({ type: 'ROOM_ID', payload: position })
      }
    }
  }

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  useEffect(() => {
    // Initialize the viewer
    let client = new window.Sketchfab(viewerIframeRef.current)
    setSketchfabClient(client)
  }, [])

  useEffect(() => {
    // Initialize the viewer
    if (sketchfabClient) {
      sketchfabClient.init(MODEL_UID, {
        camera: 1,
        blending: 1,
        autospin: rotate3DModel ? 0.2 : 0,
        ui_controls: 0,
        ui_infos: 0,
        preload: 1,
        autostart: 1,
        ui_inspector: 0,
        annotation_tooltip_visible: 0,
        transparent: 1,
        success: api => {
          api.start()
          api.addEventListener('annotationSelect', index => {
            handleClick(index)
          })
          api.addEventListener('viewerready', () => {
            console.log('Viewer is ready')
            dispatch({ type: 'MODEL_LOADED' })
          })
        },
        error: () => {
          console.log('Viewer error')
        }
      })
    }
  }, [rotate3DModel, sketchfabClient, dispatch])

  // This ref will contain the actual iframe object
  const viewerIframeRef = useRef(null)

  const ViewerIframe = (
    <>
      <div className='gc-sketchfab-container' id='gcSketchfabContainer'>
        <iframe
          className='display-area'
          ref={viewerIframeRef}
          title='click and move the 3D model'
          width='100%'
          height={height}
        />
      </div>
      {displayedElements.legend && (
        <div className='legend'>
          <h2>{legendTitle}</h2>
          {legendItems.map((item, i) => {
            if (i === activeItem) {
              setLegendItemClass('legend-item active-green')
              setNumberClass('active-number')
            }
            legendItemId = 'legendItem_' + i
            return (
              <div
                key={i}
                className={legendItemClass}
                id={legendItemId}
                onClick={() => handleClick(i)}
              >
                <span className={numberClass}>{item?.number}</span>
                <span className='title'>{item?.title}</span>
              </div>
            )
          })}
        </div>
      )}
      {roomData && (
        <Modal
          isOpen={modalOpen}
          onRequestClose={toggleModal}
          className='gc-modal'
          overlayClassName='gc-modal-overlay'
          style={initialValues.modalStyle}
        >
          {modalContentRoomDetail(
            toggleModal,
            initialValues.sliderSettings,
            currentSlide,
            roomData
          )}
        </Modal>
      )}
    </>
  )
  return ViewerIframe
}
