import React, { useState } from 'react'
import exitIcon from '../../assets/icons/icon_exit.png'
import Modal from 'react-modal'
import { modalExitForm, feedbackForm } from '../modal/helpers'

// Redux
import { useSelector } from 'react-redux'

Modal.setAppElement('#root')

const ExitBtn = () => {
  // Redux
  const common = useSelector(state => state)
  const [callUsText, setCallUsText] = useState(
    common?.data?.exit?.modal?.callUs
  )
  const [displayFeedbackForm, setDisplayFeedbackForm] = useState(false)

  const initialValues = {
    wrapperWidth: 60,
    exitText: null,
    exitTextStyle: {
      display: 'block',
      paddingTop: 0
    },
    modalOpen: false,
    gdprClick: false
  }
  const [wrapperWidth, setWrapperWidth] = useState(initialValues.wrapperWidth)
  const [exitText, setExitText] = useState(initialValues.exitText)
  const [exitTextStyle, setExitTextStyle] = useState(
    initialValues.exitTextStyle
  )
  const [modalOpen, setModalOpen] = useState(initialValues.modalOpen)
  const [gdprClick, setGdprClick] = useState(initialValues.gdprClick)

  function toggleModal () {
    if (modalOpen) {
      setDisplayFeedbackForm(false)
      setCallUsText(common?.data?.exit?.modal?.callUs)
    }
    setModalOpen(!modalOpen)
  }

  function toogleBackClick () {
    setDisplayFeedbackForm(false)
  }

  function toogleGdprClick () {
    setGdprClick(!gdprClick)
  }

  function redirectToUrl () {
    window.location.href = common?.data?.exit?.modal?.exitUrl
  }
  function callUs () {
    setCallUsText(common?.data?.exit?.modal?.callUsNumber)
    if (callUsText === common?.data?.exit?.modal?.callUsNumber) {
      window.open('tel:' + common?.data?.exit?.modal?.callUsNumber)
    }
  }
  function feedback () {
    setDisplayFeedbackForm(true)
  }

  const exitWrapperStyle = {
    width: wrapperWidth
  }

  const onMouseEnterHandler = () => {
    setWrapperWidth(230)
    setExitText(common?.data?.exit?.title)
    setExitTextStyle({
      paddingTop: '6px'
    })
  }

  const onMouseLeaveHandler = () => {
    setWrapperWidth(initialValues.wrapperWidth)
    setExitText(initialValues.exitText)
    setExitTextStyle(initialValues.exitTextStyle)
  }
  const urls = {
    redirectToUrl,
    callUs,
    feedback
  }

  return (
    <>
      <div
        className='gc-exit-wrapper link'
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        onClick={toggleModal}
        style={exitWrapperStyle}
      >
        <div style={exitTextStyle} className='gc-exit-text'>
          {exitText}
        </div>
        <img className='gc-header-icon-exit' src={exitIcon} alt='Exit icon' />
      </div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={toggleModal}
        className='gc-modal exit-window'
        overlayClassName='gc-modal-overlay'
      >
        {!displayFeedbackForm &&
          modalExitForm(toggleModal, urls, common?.data, callUsText)}
        {displayFeedbackForm &&
          feedbackForm(
            toggleModal,
            common?.data,
            toogleBackClick,
            gdprClick,
            toogleGdprClick
          )}
      </Modal>
    </>
  )
}

export default ExitBtn
