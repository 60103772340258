import React, { useState } from 'react'

// Components
import Problem from './Problem'
import View from './View'

// Assets
import ArrowDown from '../../../assets/icons/arrow_down.png'

// Redux
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

// Import css files
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Testimonials = () => {
  const [selected, setSelected] = useState(null)

  // Redux
  const opened = useSelector(state => state.openedWidgets.problems)
  const footer = useSelector(state => state.data.footer)
  const problems = useSelector(state => state.data.problems)

  const dispatch = useDispatch()

  const closeWidget = () => {
    dispatch({ type: 'CLOSE_WIDGET', payload: 'problems' })
  }

  if (!opened) {
    return true
  }

  return (
    <div className='gc-problems gc-widget animation'>
      <div className='head'>
        <div className='title'>
          {footer?.problems.title}
          <hr
            style={{
              maxWidth: '25px',
              margin: '5px 0px 0px 0px'
            }}
          />
        </div>
        <div className='arrow' onClick={closeWidget}>
          <img src={ArrowDown} alt='Arrow down' />
        </div>
      </div>
      <div className='body slick-wrapper'>
        {/* List of problems */}
        {!selected &&
          problems.map((problem, i) => {
            return (
              <div key={i} onClick={i => setSelected(problem)}>
                <Problem text={problem.problem} />
              </div>
            )
          })}
        {/* Selected one problem */}
        {selected && (
          <>
            <View
              problem={selected.problem}
              conflict={selected.conflict}
              solution={selected.solution}
            />
            <a
              onClick={i => {
                i.preventDefault()
                setSelected(null)
              }}
              href='#displayProblem'
              className='gc-btn'
            >
              {'Back'}
            </a>
          </>
        )}
      </div>
    </div>
  )
}

export default Testimonials
