import React from 'react'

// Components
import Modal from 'react-modal'

// Modal content
import MobileContent from '../components/modal/Mobile'

const Desktop = () => {
  return (
    <div className='gc-layout-desktop'>
      <Modal
        isOpen={true}
        className='gc-modal gc-modal-mobile'
        overlayClassName='gc-modal-overlay'
      >
        {MobileContent()}
      </Modal>
    </div>
  )
}

export default Desktop
