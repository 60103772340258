import React from 'react'

// Assets
import IconSolution from '../../../assets/icons/feedback-icon_hover.png'


const View = props => {
    const { question, answer } = props

    return (
        <div className='view'>
            <div
                className='solution-title'
                dangerouslySetInnerHTML={{ __html: question }}
            ></div>
            <div className='title'>
                <div className='icon'>
                    <img className="icon-solution" src={IconSolution} alt='Solution' />
                </div>
                <div className='text' dangerouslySetInnerHTML={{ __html: answer }}></div>
            </div>
        </div>
    )
}

export default View
