import React, { useState } from 'react'
import exitIcon from '../../assets/icons/icon-overview.png'

// Redux
import { useSelector, useDispatch } from 'react-redux'

const OverviewBtn = () => {
  // Redux
  const common = useSelector(state => state)
  const dispatch = useDispatch()

  const initialValues = {
    wrapperWidth: 60,
    overviewText: null,
    textStyle: {
      display: 'block',
      paddingTop: 0
    }
  }
  const [wrapperWidth, setWrapperWidth] = useState(initialValues.wrapperWidth)
  const [overviewText, setOverviewText] = useState(initialValues.overviewText)
  const [textStyle, setTextStyle] = useState(initialValues.textStyle)

  const exitWrapperStyle = {
    width: wrapperWidth
  }

  const onMouseEnterHandler = () => {
    setWrapperWidth(230)
    setOverviewText(common?.data?.backToOverview?.title)
    setTextStyle({
      paddingTop: '8px'
    })
  }

  const onMouseLeaveHandler = () => {
    setWrapperWidth(initialValues.wrapperWidth)
    setOverviewText(initialValues.overviewText)
    setTextStyle(initialValues.textStyle)
  }

  const onClickHandler = () => {
    dispatch({
      type: 'GENERAL_ROOM_CHANGE'
    })
  }

  return (
    <>
      <div
        className='gc-overview-wrapper link'
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        onClick={onClickHandler}
        style={exitWrapperStyle}
      >
        <div style={textStyle} className='gc-overview-text'>
          {overviewText}
        </div>
        <img
          className='gc-header-icon-overview'
          src={exitIcon}
          alt='Exit icon'
        />
      </div>
    </>
  )
}

export default OverviewBtn
