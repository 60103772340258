import React, { useState } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Modal from 'react-modal'
import ReactPlayer from 'react-player'

// Redux
import { useSelector } from 'react-redux'

const Content = () => {
  // Redux
  const common = useSelector(state => state)
  const videos = common?.data?.faq?.videos
  const placeholder =
    'https://firebasestorage.googleapis.com/v0/b/greencooling-showroom-dev.appspot.com/o/images%2Ffaq-video%2Fvideo-placeholder.png?alt=media&token=22e36a7c-85eb-47fa-b2da-6f1003508fa2'
  const [modalOpen, setModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState(null)

  const modalStyle = {
    content: {
      width: 'calc(100vh - 100px)',
      height: '444px'
    }
  }

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  const onClickHandler = e => {
    setModalOpen(true)
    setModalContent(videos[e.target.id])
  }

  const renderVideos = videos.map((item, index) => (
    <Col className='faq-item' md={4} key={index}>
      <div className='video-box'>
        <div className='placeholder' onClick={onClickHandler}>
          <img src={placeholder} alt='greencooling' id={index} />
        </div>
        <div className='title'>{item.title}</div>
        <div className='text'>{item.text}</div>
      </div>
    </Col>
    // </div>
  ))

  return (
    <>
      <div className='faq'>
        <div className='faq-title'>{common?.data?.faq?.title}</div>
        <Row className='faq-items'>{renderVideos}</Row>
      </div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={toggleModal}
        className='gc-modal'
        overlayClassName='gc-modal-overlay'
        style={modalStyle}
      >
        {modalContent && modalContent.url && (
          <>
            <ReactPlayer
              url={modalContent.url}
              width='100%'
              height='100%'
              playing
            />
          </>
        )}
      </Modal>
    </>
  )
}

export default Content
