import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import TagManager from 'react-gtm-module'

// Styles
import exitModalIcon from '../../assets/icons/icon_modal_exit.png'

// Redux
import { useSelector } from 'react-redux'

const CookieConsent = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [marketing, setMarketing] = useState(false)
  const [maps, setMaps] = useState(false)

  const data = useSelector(state => state.data.cookieConsent)

  // Load locastorage saved preferencies
  useEffect(() => {
    const loaded = {
      marketing: localStorage.getItem('marketing') === 'true',
      maps: localStorage.getItem('maps') === 'true',
      cookieConsentSaved: localStorage.getItem('cookieConsentSaved') === 'true'
    }

    // Set preset
    setMarketing(loaded.marketing)
    setMaps(loaded.maps)

    if (!loaded.cookieConsentSaved) {
      setModalOpen(true)
    } else {
      loaded.marketing && enableMarketing()
      loaded.maps && enableMaps()
    }
  }, [])

  // If marketing is marked
  const enableMarketing = () => {
    const tagManagerArgs = {
      gtmId: data.gtm
    }
    TagManager.initialize(tagManagerArgs)
  }

  // If maps is marked
  const enableMaps = () => {
    // Enable cookie maps
  }

  // After window is closed or selected save selected
  const toggleModal = () => {
    marketing && enableMarketing()
    maps && enableMaps()
    saveLocalStorage()
    setModalOpen(!modalOpen)
  }

  // When all tracking codes are enabled
  const acceptAll = () => {
    enableMarketing()
    enableMaps()
    saveLocalStorage()
    setModalOpen(!modalOpen)
  }

  const saveLocalStorage = () => {
    localStorage.setItem('cookieConsentSaved', true)
    localStorage.setItem('marketing', marketing)
    localStorage.setItem('maps', maps)
  }

  if (!data.enabled) {
    return false
  }

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={toggleModal}
      className='gc-modal'
      overlayClassName='gc-modal-overlay'
      style={{
        overlay: { zIndex: 1 },
        content: {
          width: 'calc(70vw)',
          height: 'calc(70vh)',
          maxHeight: '420px',
          overflow: 'auto'
        }
      }}
    >
      <div className='gc-modal-window'>
        <div className='gc-modal-exit'>
          <img
            className='gc-header-icon-exit link'
            src={exitModalIcon}
            alt='Exit icon'
            onClick={() => toggleModal()}
          />
        </div>
        <div className='gc-modal-title'>{data.text.title || ''}</div>
        <div
          className='gc-modal-content'
          style={{
            overflow: 'auto'
          }}
        >
          <div className='gc-cc-wrapper'>
            <div className='gc-cc-subtext'>{data.text.subText || ''}</div>
            <div className='gc-cc-selector-wrapper'>
              <div
                onClick={() => setMarketing(!marketing)}
                className='gc-cc-selector'
              >
                <div className='gc-cc-inputs'>
                  <input
                    className='checkbox'
                    type='checkbox'
                    checked={marketing}
                    onClick={() => setMarketing(!marketing)}
                  />
                  <div className='checkmark'></div>
                </div>
                <div>
                  <span className='gc-cc-title'>
                    {data.text.marketingTitle || ''}
                  </span>
                  <br />
                  {data.text.marketingSubText || ''}
                </div>
              </div>
              <div onClick={() => setMaps(!maps)} className='gc-cc-selector'>
                <div className='gc-cc-inputs'>
                  <input
                    className='checkbox'
                    type='checkbox'
                    checked={maps}
                    onClick={() => setMaps(!maps)}
                  />
                  <div className='checkmark'></div>
                </div>
                <div>
                  <span className='gc-cc-title'>
                    {data.text.mapsTitle || ''}
                  </span>
                  <br />
                  {data.text.mapsSubText || ''}
                </div>
              </div>
              <div className='gc-cc-selector'>
                <div className='gc-cc-inputs'>
                  <input className='checkbox' type='checkbox' checked={true} />
                  <div className='checkmark'></div>
                </div>
                <div>
                  <span className='gc-cc-title'>
                    {data.text.basicTitle || ''}
                  </span>
                  <br />
                  {data.text.basicSubText || ''}
                </div>
              </div>
            </div>
            <div className='gc-cc-btns'>
              <button className='gc-btn grey' onClick={() => toggleModal()}>
                {data.text.buttonSave || ''}
              </button>
              <button className='gc-btn' onClick={() => acceptAll()}>
                {data.text.buttonAcceptAll || ''}
              </button>
            </div>
            <div className='gc-cc-link'>
              <a
                href={data.text.textURL || ''}
                target='_blank'
                rel='noopener noreferrer'
              >
                {' '}
                {data.text.textLink || ''}
              </a>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CookieConsent
