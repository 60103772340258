import React, { useEffect, useState } from 'react'

import { feedbackForm } from '../../modal/helpers'
import Modal from 'react-modal'

// Assets
import IconEmail from '../../../assets/icons/icon_email.png'
import IconPhone from '../../../assets/icons/icon_phone.png'

// Redux
import { useSelector } from 'react-redux'

const Contact = props => {
  const common = useSelector(state => state)
  // Redux
  const openedPhone = useSelector(state => state.openedWidgets.contactPhone)
  const openedEmail = useSelector(state => state.openedWidgets.contactEmail)
  const items = useSelector(state => state.data.contact)
  const [displayFeedbackForm, setDisplayFeedbackForm] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const [gdprClick, setGdprClick] = useState(false)

  useEffect(() => {
    setModalOpen(true)
    setDisplayFeedbackForm(true)
  }, [openedEmail])

  let opened = openedEmail
  if (openedPhone) {
    opened = openedPhone
  }
  if (!opened) {
    return true
  }

  function toggleModal () {
    if (modalOpen) {
      setDisplayFeedbackForm(false)
    }
    setModalOpen(!modalOpen)
  }

  function toogleBackClick () {
    setDisplayFeedbackForm(false)
    setModalOpen(false)
  }

  function toogleGdprClick () {
    setGdprClick(!gdprClick)
  }

  const phoneCall = phone => {
    window.open('tel:' + phone)
  }

  return (
    <>
      {openedEmail && (
        <>
          <Modal
            isOpen={modalOpen}
            onRequestClose={toggleModal}
            className='gc-modal exit-window'
            overlayClassName='gc-modal-overlay'
          >
            {displayFeedbackForm &&
              feedbackForm(
                toggleModal,
                common?.data,
                toogleBackClick,
                gdprClick,
                toogleGdprClick
              )}
          </Modal>
        </>
      )}
      {openedPhone && (
        <div className='gc-contact animation'>
          <div className='body'>
            <div className='title'>{common?.data?.header?.contact?.title}</div>
            <div className='subtitle'>
              {common?.data?.header?.contact?.subTitle}
            </div>
            {items.map(item => {
              const phone = item.phone
              return (
                <>
                  {openedPhone && (
                    <div className='panel'>
                      <div className='image'>
                        <img
                          src={item.image.src}
                          width='126'
                          height='126'
                          alt={item.name}
                        />
                      </div>
                      <div className='contact'>
                        <div className='name'>{item.name}</div>
                        <div className='desc'>
                          <div className='role'>{item.role}</div>
                          <div className='company'>{item.company}</div>
                        </div>
                        <div className='e-mail'>
                          <div className='icon'>
                            <img src={IconEmail} alt='icon' />
                          </div>
                          <div className='text'>
                            <a href={'mailto:' + item.email}>{item.email}</a>
                          </div>
                        </div>
                        {phone && phone !== '' && (
                          <div
                            className='phone'
                            onClick={() => phoneCall(phone)}
                          >
                            <div className='icon'>
                              <img src={IconPhone} alt='icon' />
                            </div>
                            <div className='text'>{phone}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )
            })}
          </div>
        </div>
      )}
    </>
  )
}

export default Contact
