import React from 'react'

const Testimonial = props => {
  const { image, link } = props

  return (
    <div className='testimonial'>
      <div className='image'>
        <a href={link.href} target={link.external && '_blank'}>
          <img src={image.src} alt={image.alt} />
        </a>
      </div>
    </div>
  )
}

export default Testimonial
