import React from 'react'

// Assets
import IconQA from '../../../assets/icons/q_a.svg'
import IconSolution from '../../../assets/icons/icon_solution.png'

// Redux
import { useSelector } from 'react-redux'

const View = props => {
  const { question, answer } = props

  // Redux
  const translation = useSelector(
    state => state.data.translations.widgets.questions
  )

  return (
    <div className='view'>
      <div className='title'>
        <div className='icon'>
          <img src={IconQA} width='29' alt='QA' />
        </div>
        <div className='problem'>{translation.question}</div>
      </div>
      <div
        className='text'
        dangerouslySetInnerHTML={{ __html: question }}
      ></div>
      <div className='title'>
        <div className='icon'>
          <img src={IconSolution} alt='Answer' />
        </div>
        <div className='problem'>{translation.answer}</div>
      </div>
      <div className='text' dangerouslySetInnerHTML={{ __html: answer }}></div>
    </div>
  )
}

export default View
