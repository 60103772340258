import React, { useState } from 'react'
import Slider from 'react-slick'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import axios from 'axios'

import exitModalIcon from '../../assets/icons/icon_modal_exit.png'
import exitIcon from '../../assets/icons/icon_exit_modal_blue.png'
import printIcon from '../../assets/icons/icon-print.png'
import downloadIcon from '../../assets/icons/icon-download.png'
import downloadIconOverview from '../../assets/icons/icon-download-overview.png'

// Import css files
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export const modalExitForm = (modalClick, urls, data, callUsText) => {
  const modalTexts = data?.exit?.modal

  return (
    <div className='gc-modal-window exit-form'>
      <div className='gc-modal-exit'>
        <img
          className='gc-header-icon-exit link'
          src={exitModalIcon}
          alt='Exit icon'
          onClick={modalClick}
        />
      </div>
      <div className='gc-modal-title'>{modalTexts?.title}</div>
      <div className='gc-modal-content'>
        <p>{modalTexts?.content}</p>
        <ul className='flex-container'>
          <li className='flex-item gc-green-button'>
            <a className='link' onClick={urls?.callUs}>
              {callUsText}
            </a>
          </li>
          <li className='flex-item gc-midle'>or</li>
          <li className='flex-item gc-green-button'>
            <a className='link' onClick={urls?.feedback}>
              {modalTexts?.feedback}
            </a>
          </li>
        </ul>
        <div
          className='gc-modal-exit-wrapper link'
          onClick={urls?.redirectToUrl}
        >
          <img
            className='gc-header-icon-exit modal-exit-icon'
            src={exitIcon}
            alt='Exit icon'
          />
          <span>{data?.exit?.title}</span>
        </div>
      </div>
    </div>
  )
}
export const modalContentImageGallery = (
  modalClick,
  sliderSettings,
  currentSlide,
  data
) => {
  const currentSlideData = data[currentSlide]
  return (
    <div className='gc-modal-window'>
      <div className='gc-modal-exit absolute-right'>
        <img
          className='gc-header-icon-exit link'
          src={exitModalIcon}
          alt='Exit icon'
          onClick={modalClick}
        />
      </div>
      <div className='gc-modal-slider'>
        <Slider {...sliderSettings}>
          {data.map((item, i) => {
            const imgUrl = item?.src
            const imageStyle = {
              backgroundImage: 'url(' + imgUrl + ')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: '50% 50%',
              height: '100%'
            }
            return (
              <div key={i} className='slide'>
                <div className='image'>
                  <div style={imageStyle}></div>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
      <div className='slide-content'>
        <div className='slide-title'>{currentSlideData?.title}</div>
        <div
          className='slide-text'
          dangerouslySetInnerHTML={{ __html: currentSlideData?.text }}
        />
        {currentSlideData?.button && (
          <div
            className='gc-green-button link'
            // onClick={currentSlideData?.button?.url}
          >
            {currentSlideData?.button?.name}
          </div>
        )}
      </div>
    </div>
  )
}
export const modalContentDownload = (
  modalClick,
  data,
  downloadForm,
  downloadFormStatus
) => {
  const actionDownload = data.actions[0].download
  const errorMessages = actionDownload.inputs.errorMessages
  const handleButtonClick = () => {
    console.log('clicked url:: ', data?.button?.url)
  }
  const handleDownloadClick = () => {
    console.log('clicked download ')
    downloadForm()
  }
  const handlePrintClick = () => {
    console.log('clicked print ')
  }
  return (
    <div className='gc-modal-window'>
      <div className='gc-modal-exit absolute-right'>
        <img
          className='gc-header-icon-exit link'
          src={exitModalIcon}
          alt='Exit icon'
          onClick={modalClick}
        />
      </div>
      {!downloadFormStatus ? (
        <>
          <div className='download-content'>
            <h2>{data.title}</h2>
            {data.text}
          </div>
          <div className='download-controlls'>
            <div className='icons'>
              <span className='icon icon-print' onClick={handlePrintClick}>
                <img src={printIcon} alt='print' />
              </span>
              <span
                className='icon icon-download'
                onClick={handleDownloadClick}
              >
                <img src={downloadIcon} alt='download' />
              </span>
            </div>
            <div className='buttons'>
              <div className='gc-modal-buttons'>
                <div
                  className='gc-green-button link'
                  onClick={handleButtonClick}
                >
                  {data?.button?.name}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='download-content'>
            <div className='action'>
              <div className='caption'>
                <div className='caption-img'>
                  <img src={downloadIconOverview} alt='download' />
                </div>
                <div className='caption-title'>{actionDownload.title}</div>
              </div>
              <div className='text'>{actionDownload.text}</div>
              <div className='form'>
                <Formik
                  initialValues={{
                    email: '',
                    name: '',
                    company: ''
                  }}
                  validate={values => {
                    const errors = {}
                    if (!values.name) {
                      errors.name = errorMessages.name
                    }
                    if (!values.company) {
                      errors.company = errorMessages.company
                    }
                    if (!values.email) {
                      errors.email = errorMessages.email
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                      )
                    ) {
                      errors.email = errorMessages.invalidEmail
                    }
                    return errors
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      console.log('form submitted:: ', values)
                      setSubmitting(false)
                    }, 400)
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form className='download-form'>
                      <div className='input-title'>
                        {actionDownload.inputs.name}
                      </div>
                      <Field type='text' name='name' />
                      <ErrorMessage
                        name='name'
                        component='div'
                        className='error-message'
                      />
                      <div className='input-title'>
                        {actionDownload.inputs.company}
                      </div>
                      <Field type='text' name='company' />
                      <ErrorMessage
                        name='company'
                        component='div'
                        className='error-message'
                      />
                      <div className='input-title'>
                        {actionDownload.inputs.email}
                      </div>
                      <Field type='email' name='email' />
                      <ErrorMessage
                        name='email'
                        component='div'
                        className='error-message'
                      />
                      <button
                        type='submit'
                        className='gc-green-button'
                        disabled={isSubmitting}
                      >
                        Submit
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
export const feedbackForm = (modalClick, data, backClick) => {
  const exitModal = data.exit.modal
  const feedbackForm = exitModal.feedbackForm
  const errorMessages = feedbackForm.errorMessages

  let buttonClassName = 'gc-green-button'
  return (
    <>
      <div className='gc-modal-window'>
        <div className='gc-modal-exit'>
          <img
            className='gc-header-icon-exit link'
            src={exitModalIcon}
            alt='Exit icon'
            onClick={modalClick}
          />
        </div>
        <div className='gc-modal-title'>{exitModal.feedback}</div>
        <div
          className='gc-modal-content'
          style={{ maxHeight: '80vh', overflow: 'auto' }}
        >
          <div className='feedback-form'>
            <div className='action'>
              <div className='form'>
                <Formik
                  initialValues={{
                    email: '',
                    firstname: '',
                    lastname: '',
                    company: '',
                    message: '',
                    gdpr: false
                  }}
                  validate={values => {
                    const errors = {}
                    if (!values.firstname) {
                      errors.firstname = errorMessages.firstname
                    }
                    if (!values.lastname) {
                      errors.lastname = errorMessages.lastname
                    }
                    if (!values.company) {
                      errors.company = errorMessages.company
                    }
                    if (!values.message) {
                      errors.message = errorMessages.message
                    }
                    if (!values.gdpr) {
                      errors.gdpr = errorMessages.gdpr
                    }
                    if (!values.email) {
                      errors.email = errorMessages.email
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                      )
                    ) {
                      errors.email = errorMessages.invalidEmail
                    }
                    return errors
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    axios
                      .post(
                        'https://us-central1-greencooling-showroom.cloudfunctions.net/contactForm',
                        {
                          firstName: values.firstname,
                          lastName: values.lastname,
                          company: values.company,
                          email: values.email,
                          message: values.message,
                          gdpr: values.gdpr
                        },
                        {
                          headers: {
                            'Content-Type': 'application/json'
                          }
                        }
                      )
                      .then(res => {
                        console.log(res)
                        console.log(res.data)
                        setSubmitting({ error: false, submitted: true })
                      })
                      .catch(error => {
                        console.log('error', error)
                        setSubmitting({ error: true, submitted: false })
                      })
                  }}
                >
                  {({ isSubmitting }) => {
                    if (isSubmitting.submitted) {
                      return (
                        <>
                          <div>{feedbackForm.submittedMessage}</div>
                          <br />
                          <br />
                          <div className='buttons'>
                            <button
                              className='gc-green-button button-back'
                              onClick={backClick}
                            >
                              {feedbackForm.buttonBack.title}
                            </button>
                          </div>
                        </>
                      )
                    }

                    return (
                      <Form className='download-form'>
                        {isSubmitting.error && (
                          <div style={{ color: 'red' }}>
                            {feedbackForm.submittedError}
                          </div>
                        )}
                        <div className='input-title'>
                          *{feedbackForm.firstname}
                        </div>
                        <Field type='text' name='firstname' />
                        <ErrorMessage
                          name='firstname'
                          component='div'
                          className='error-message'
                        />
                        <div className='input-title'>
                          *{feedbackForm.lastname}
                        </div>
                        <Field type='text' name='lastname' />
                        <ErrorMessage
                          name='lastname'
                          component='div'
                          className='error-message'
                        />
                        <div className='input-title'>
                          *{feedbackForm.company}
                        </div>
                        <Field type='text' name='company' />
                        <ErrorMessage
                          name='company'
                          component='div'
                          className='error-message'
                        />
                        <div className='input-title'>*{feedbackForm.email}</div>
                        <Field type='email' name='email' />
                        <ErrorMessage
                          name='email'
                          component='div'
                          className='error-message'
                        />
                        <div className='input-title'>
                          *{feedbackForm.message}
                        </div>
                        <Field component='textarea' name='message' />
                        <ErrorMessage
                          name='message'
                          component='div'
                          style={{ marginTop: '-16px', marginBottom: '16px' }}
                          className='error-message'
                        />
                        <label className='gdpr-section'>
                          <Field
                            type='checkbox'
                            className='input-checkbox'
                            name='gdpr'
                          />
                          <span className='checkmark'></span>
                          <div
                            className='input-title input-gdpr'
                            dangerouslySetInnerHTML={{
                              __html: '*' + feedbackForm.gdpr
                            }}
                          ></div>
                        </label>
                        <ErrorMessage
                          name='gdpr'
                          component='div'
                          className='error-message'
                        />
                        <div className='buttons'>
                          <a
                            className='gc-green-button button-back'
                            onClick={backClick}
                          >
                            {feedbackForm.buttonBack.title}
                          </a>
                          <button type='submit' className={buttonClassName}>
                            {feedbackForm.buttonSubmit.title}
                          </button>
                        </div>
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export const modalContentRoomDetail = (
  modalClick,
  sliderSettings,
  currentSlide,
  data
) => {
  const points = data.points
  const currentSlideData = points[currentSlide + 1].images[0]
  return (
    <div className='gc-modal-window'>
      <div className='gc-modal-exit absolute-right'>
        <img
          className='gc-header-icon-exit link'
          src={exitModalIcon}
          alt='Exit icon'
          onClick={modalClick}
        />
      </div>
      <div className='gc-modal-slider'>
        <Slider {...sliderSettings}>
          {Object.keys(points).map((item, i) => {
            const imgUrl = points[item].images[0].src
            const imageStyle = {
              backgroundImage: 'url(' + imgUrl + ')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: '50% 50%',
              height: '100%'
            }
            return (
              <div key={i} className='slide'>
                <div className='image'>
                  <div style={imageStyle}></div>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
      <div className='slide-content'>
        <div className='slide-title'>{currentSlideData?.title}</div>
        <div
          className='slide-text'
          dangerouslySetInnerHTML={{ __html: currentSlideData?.text }}
        />
      </div>
    </div>
  )
}
export const modalRoomDescription = (
  modalClick,
  roomDescription,
  roomTitle
) => {
  return (
    <div className='gc-modal-window'>
      <div className='modal-room-title-wrapper'>
        {roomTitle && <h2 className='modal-room-title'>{roomTitle}</h2>}
        <img
          className='gc-header-icon-exit link'
          src={exitModalIcon}
          alt='Exit icon'
          onClick={modalClick}
          style={{ float: 'right' }}
        />
      </div>
      <div
        className='room-description-text'
        dangerouslySetInnerHTML={{ __html: roomDescription }}
      />
    </div>
  )
}

export const modalRoomYoutube = (modalClick, link, width, height, text) => {
  return (
    <div className='gc-modal-window'>
      <div className='sticky-right'>
        <img
          className='gc-header-icon-exit link'
          src={exitModalIcon}
          alt='Exit icon'
          onClick={modalClick}
        />
      </div>
      <div className='gc-modal-iframe'>
        <iframe
          width={width}
          height={height}
          src={link}
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        ></iframe>
        <div
          className='gc-modal-text'
          dangerouslySetInnerHTML={{ __html: text }}
          style={{ maxWidth: width - 20 + 'px' }}
        />
      </div>
    </div>
  )
}
